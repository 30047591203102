import React from "react";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import Layout from "../components/Layout";
import MobileMenu from "../components/MobileMenu";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import BlogPost from "../components/BlogPost";
import PostPagination from "../components/PostPagination";
import Footer from "../components/Footer";

const PressPage = ({ data, pageContext }) => {
  // console.log("PressPage", data);
  return (
    <Layout pageTitle="Sajtó | MyFoodProtocol">
      <Header inner={true} btnClass="main-nav__btn-two" />
      <MobileMenu inner={true} />
      <PageHeader
        pageHeading={
          pageContext.title ? pageContext.title : "My Food Protocol Sajtó"
        }
        breadcrumb={[{ url: "/press", title: "Sajtó" }]}
      />

      <section className="blog-grid">
        <Container>
          <Row>
            {data?.allStrapiPost?.nodes &&
              data?.allStrapiPost?.nodes.map((item) => {
                return <BlogPost key={item.strapi_id} data={item} />;
              })}
          </Row>
          <PostPagination
            page={pageContext.page ?? 1}
            pages={Math.ceil(data?.allStrapiPost?.totalCount / 6)}
          />
        </Container>
      </section>
      <Footer />
    </Layout>
  );
};

export default PressPage;

export const query = graphql`
  query PostsQuery($skip: Int) {
    allStrapiPost(
      limit: 6
      skip: $skip
      sort: { fields: Date, order: DESC }
      filter: { publishedAt: { ne: "null" }, Type: { eq: "Press" } }
    ) {
      nodes {
        strapi_id
        id
        Title
        Date
        Lead {
          data {
            Lead
          }
        }
        Image {
          url
          width
          height
          # alternativeText
          formats {
            medium {
              url
              width
              height
            }
          }
        }
      }
      totalCount
    }
  }
`;
